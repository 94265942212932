import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { Button } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { dispatch } from 'redux/actions/index';
import _ from 'lodash';
import { withSnackbar } from 'notistack';



const styles = {};

class CoreDisabled extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      sample: _.sample([true, false, false])
    };
  }

  handleClick(view) {
    const config = this.props.config

    if (view === '/startCore') {
      const that = this
      const sendNotification = function (text, variant) {
        that.props.enqueueSnackbar(text, {
          variant: variant || 'success',
          preventDuplicate: true,
          style: { whiteSpace: 'pre-line' },
        });
      };
      let telegramError = false;
      let orphanPair = false;
      let orphanStrat = [];
      // fill orphan vars
      Object.keys(config.pairs).forEach(exchange => {
        if (Object.keys(config.exchanges).indexOf(exchange) === -1) {
          orphanPair = true;
        }
        Object.keys(config.pairs[exchange]).forEach(pair => {
          if (Object.keys(config.strategies).indexOf(config.pairs[exchange][pair].strategy) === -1) {
            orphanStrat.push(`${pair} (${exchange})`);
          }
        });
      });
      // check telegramError
      if (
        config.bot.TELEGRAM_ENABLED === true &&
        (config.bot.chat_id === '' || config.bot.admin_id === '' || config.bot.TOKEN === '')
      ) {
        telegramError = true;
      }
      // actions
      if (!_.isNil(config.bot.gunthy_wallet) && config.bot.gunthy_wallet === '') {
        sendNotification('Enter your Gunthy wallet address first', 'info');
      } else if (_.isEmpty(config.exchanges)) {
        sendNotification('Connect an exchange first');
      } else if (orphanPair === true) {
        sendNotification('Connect missing exchange for one or more trading pairs', 'info');
      } else if (orphanStrat.length > 0) {
        sendNotification(`Assign a valid strategy name to: ${orphanStrat.join(', ')}`, 'info');
      } else if (telegramError === true) {
        sendNotification(
          'Telegram token, chat ID and/or admin ID not set. \nDisable the Telegram bot or add the right credentials.',
          'info',
        );
      } else {
        this.props.dispatch('startBotCore');
      }

    }
    else {
      this.props.history.push(view);
    }
  }

  handleClose = () => {
    this.setState(() => ({
      open: false
    }));
  };

  getDialogContent() {

    const hasPairs =
      Object.keys(this.props.pairs).length > 0 &&
      Object.keys(this.props.pairs?.[Object.keys(this.props.pairs)[0]]).length > 0;
    const hasOrderNumberKey = !_.isNil(this.props.bot?.orderNumber);
    const hasGunthyWallet = !_.isNil(this.props.bot?.gunthy_wallet) && this.props.bot?.gunthy_wallet.length > 1;
    const hasExchangeAndOrderNumber = hasOrderNumberKey
      ? Object.keys(this.props.exchanges).length > 0 && this.props.bot?.orderNumber.length > 0
      : false;
    const modeString = _.isNil(this.props.bot?.GB_SIMULATOR)
      ? 'trading'
      : this.props.bot?.GB_SIMULATOR === true
        ? 'simulator'
        : 'trading';

        let data = {
      header: '',
      text: '',
      buttonText: '',
      buttonTarget: ''
    }

    if ((hasPairs && hasExchangeAndOrderNumber && hasOrderNumberKey) || (hasGunthyWallet && hasPairs)) {
      data.header = `Start trading`
      data.text = `Click 'Start ${modeString}' to activate your trading strategies. Please note that starting or stopping briefly refreshes this page. Use this page to keep an eye on your pairs in real time. `
      data.buttonText = `Start ${modeString}`
      data.buttonTarget = '/startCore'
    }
    else if ((!hasPairs && hasExchangeAndOrderNumber && hasOrderNumberKey) || (hasGunthyWallet && !hasPairs)) {
      data.header = `Add trading pairs`
      data.text = `There are no configured trading pairs, add one or more pairs to trade. You can do this on the trading settings page.`
      data.buttonText = `Open trading settings`
      data.buttonTarget = '/trading-settings'
    }
    else if (hasPairs && !hasExchangeAndOrderNumber && hasOrderNumberKey) {
      data.header = `Complete setup first`
      data.text = `There are no connected exchanges or the license details are not entered yet. Go to the setup page and complete the initial configuration.`
      data.buttonText = `Open setup`
      data.buttonTarget = '/setup'
    }
    else if (!hasPairs && !hasExchangeAndOrderNumber && hasOrderNumberKey) {
      data.header = `Complete setup first`
      data.text = `There are no connected exchanges and no trading pairs. Go to the setup page and first complete the initial configuration, then add trading pairs.`
      data.buttonText = `Open setup`
      data.buttonTarget = '/setup'

    }
    else {
      data.header = `Verify your settings`
      data.text = `Make sure you've connected an exchange, have entered your license details and one or more trading pairs are configured.`
      data.buttonText = `Open setup`
      data.buttonTarget = '/setup'
    }
    return data
  }

  render() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.getDialogContent().header}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {this.getDialogContent().text}
          </DialogContentText>
        </DialogContent>
        <DialogActions style={this.state.sample ? { justifyContent: 'space-between' } : {}}>
          {this.getDialogContent().buttonTarget === '/trading-settings' && this.props.config.GUI?.DASHBOARD_MODE === 'base' ? null :
            <Button onClick={() => this.handleClick(this.getDialogContent().buttonTarget)} color="primary">
              {this.getDialogContent().buttonText}
            </Button>
          }
        </DialogActions>
      </Dialog>
    );
  }
}

export default withSnackbar(withRouter(
  connect(
    state => ({
      config: state.settings.config,
      bot: state.settings.config.bot,
      exchanges: state.settings.config.exchanges,
      pairs: state.settings.config.pairs,
    }),
    { dispatch },
  )(withStyles(styles)(CoreDisabled)),
));
